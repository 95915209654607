<template>
  <div v-if="list.length" class="neopag-suggestions">
    <transition-group name="suggestion" class="neopag-suggestions__container" tag="div">
      <div v-for="(customer) in paginatedList" :key="'suggestion'+customer.id" class="suggestion">
        <div class="dismiss" @click="ignoreCustomer(customer)">
          <i class="el-icon el-icon-close" />
        </div>
        <div class="avatar">
          <div class="image">
            <img :src="customer.selfie || $avatar" alt="selfie" />
          </div>
        </div>
        <div class="info">
          <!-- <strong>{{ customer.name.split(' ')[0] + ' ' + (customer.name.split(' ')[1] || '') }}</strong> -->
          <strong style="text-wrap: wrap;">{{customer.name}}</strong>
          <br />
          <p>
            Cliente em
            <span class="number">{{ customer.store_customers_count || 0 }}</span>
            loja{{ customer.store_customers_count === 1 ? '' : 's' }} da rede Neopag
          </p>
        </div>
        <div class="actions">
          <el-button
            :loading="preventInvite"
            size="mini"
            type="success"
            @click="$emit('invite', customer)"
          >Consultar / Convidar</el-button>
        </div>
      </div>
    </transition-group>
    <el-pagination
      :current-page.sync="pageNumber"
      :page-count="pageCount"
      class="neopag-suggestions__paginacao"
      layout="prev, pager, next"
    />
  </div>
</template>

<script>
import * as types from "@/store/types";

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    preventInvite: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      pageNumber: 1,
      perPage: this.$isMobile ? 2 : 3
    };
  },
  computed: {
    pageCount: function() {
      const l = this.list.length;
      return Math.ceil(l / this.perPage);
    },
    paginatedList: function() {
      const start = (this.pageNumber - 1) * this.perPage;
      const end = start + this.perPage;

      if (!this.list.length) {
        return [];
      }

      if (this.list.length < start) {
        return this.list;
      }

      return this.list.slice(start, end);
    }
  },
  methods: {
    ignoreCustomer(customer) {
      this.$store.commit(types.STORE_IGNORE_LIST, customer);
    }
  }
};
</script>
<style lang="scss">
.neopag-suggestions {
  display: flex;
  flex-flow: row wrap;
  &__container {
    width: 100%;
    position: relative;
  }
  .suggestion {
    // position: absolute;
    width: 80%;
    margin: 0 auto 20px auto;
    position: relative;
    height: 280px;
    @include medium-down {
      height: 200px;
    }

    @include box-shadow(1);

    @include medium-down {
      width: 80%;
    }

    .dismiss {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      cursor: pointer;
      z-index: 2;
      transition: transform 0.3s ease;
      font-size: 20px;
      color: $preto;
      &:hover {
        color: #000;
      }
    }
    .avatar {
      width: 100%;
      height: 130px;
      .image {
        position: relative;
        top: 15px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100px;
        height: 100px;
        background-color: $base-background;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 50% 35%;
        border-radius: 50%;
        @include box-shadow(1);
        img {
          width: 100px;
          height: 100px;
          border-radius: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      @include medium-down {
        height: 50px;
        .image {
          position: relative;
          top: 10px;
          width: 40px;
          height: 40px;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .info {
      height: 100px;
      width: 100%;
      font-family: $title-font;
      text-align: center;
      color: $preto;
      overflow: hidden;
      strong {
        width: 80%;
        margin: 0 auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        float: left;
        left: 10%;
        position: relative;
        @include medium-down {
          font-size: 13px;
          margin-top: 5px;
        }
      }

      p {
        margin: 1rem auto 0;
        line-height: 1.4;
        font-size: 13px;
        color: rgba($preto, 0.82);
        width: 60%;
        @include medium-down {
          font-size: 11px;
          width: 80%;
        }
      }
    }
    .actions {
      height: 50px;
      width: 100%;
      text-align: center;
      line-height: 25px;
      @include medium-down {
        height: 30px;
      }
    }
  }

  &__paginacao {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }
}
.suggestion-enter-active {
  transition: all 0.5s ease;
  backface-visibility: hidden;
}
.suggestion-enter {
  transform: rotateY(-180deg) perspective(600);
}
.suggestion-leave-to {
  opacity: 0;
}
</style>
