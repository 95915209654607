<template>
  <el-popover
    :disabled="!show"
    trigger="hover"
    placement="bottom"
    width="320">
    <div style="text-align: center;">
      <h3><small>Você atingiu o limite do seu plano.</small></h3>
      <br>
      <el-button
        size="mini"
        type="success"
        @click="$router.push({
          name: 'Planos - Visão geral'
      })">Faça o upgrade</el-button>
    </div>
    <template slot="reference">
      <slot/>
    </template>
  </el-popover>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
};
</script>
