<template>
	<div>
		<div
			ref="dealReceipt"
			style="background: #FFF; max-width: 600px; margin: 0 auto 32px auto; padding: 16px; border-radius: 5px; box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);"
		>
			<div
				class="receipt-container"
				style="background: #FFF; width: 100%; margin: 0; padding: 0; text-align: left;"
			>
				<div class="receipt-wrapper" style="width: 100%; text-align: left; margin-top: 16px;">
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';text-align: center; margin-bottom: 22px;"
					>NEOPAG</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';text-align: center; margin-bottom: 22px;"
					>COMPROVANTE NÃO FISCAL</p>
					<div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
						<p
							style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
						>Data do reparcelamento</p>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Identificador</p>
					</div>
					<div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">
							{{
							formatDate(handleInsertedAt(showSale ? saleResponse : response))
							}}
						</p>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">
							#{{
							showSale
							? (saleResponse && typeof saleResponse.identifier != "undefined")
							: response.identifier || "N/D"
							}}
						</p>
					</div>
					<div
						class="row"
						style="display: grid; grid-template-columns: repeat(2, 1fr); margin-top: 16px;"
					>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Loja</p>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">CNPJ</p>
					</div>
					<div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">{{ store.name }}</p>
						<p
							style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
						>{{ store.cnpj || "N/D" }}</p>
					</div>
					<div
						class="row"
						style="display: grid; grid-template-columns: repeat(2, 1fr); margin-top: 16px;"
					>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Cliente</p>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">CPF</p>
					</div>
					<div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
						<p
							style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
						>{{ showSale ? saleResponse.user.name : response.user.name }}</p>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">
							{{
							showSale ? saleResponse.user.cpf : response.user.cpf || "N/D"
							}}
						</p>
					</div>
					<div class="divider" style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;" />
					<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">
						Valor da reparcelamento:
						{{ formatMoney(showSale ? saleResponse.amount : response.amount) }}
					</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
					>Entrada: {{ formatMoney(calculateDownPayment) }}</p>
					<div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">
							A financiar:
							{{
							formatMoney(
							showSale
							? saleResponse.amount
							: response.amount - calculateDownPayment
							)
							}}
						</p>
						<p
							style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
						>Juros: {{ showSale ? saleResponse.interest : response.interest }}%</p>
					</div>
					<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">
						Valor total:
						{{
						formatMoney(
						showSale ? saleResponse.total_amount : response.total_amount
						)
						}}
					</p>
					<div class="divider" style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;" />
					<div
						class="installments-row"
						style="display: grid; grid-template-columns: repeat(3, 1fr);"
					>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Parcelas</p>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Vencimento</p>
						<p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Valor</p>
					</div>
					<div
						v-for="installment in showSale
							? saleResponse.installments
							: response.installments"
						:key="installment.number"
						class="installments-row"
						style="display: grid; grid-template-columns: repeat(3, 1fr);"
					>
						<p
							style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
						>{{ installment.number }}</p>
						<p
							style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
						>{{ formatStatementDate(installment.due_date, false) }}</p>
						<p
							style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
						>{{ formatMoney(installment.amount) }}</p>
					</div>
					<div class="divider" style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;" />
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
					>Reconheco a dívida acima</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
					>Assinatura do cliente autorizando esse reparcelamento:</p>
					<div
						class="signature"
						style="padding-top: 80px; width: 300px; margin: 0 auto 8px auto; border-bottom: solid 1px #000;"
					/>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
					>{{ showSale && saleResponse && saleResponse.user && saleResponse.user.name ? saleResponse.user.name : response.user.name }}</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
					>CPF: {{ showSale ? saleResponse.user.cpf : response.user.cpf }}</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center; margin-top: 32px;"
					>Reparcelamento realizado de acordo com os termos:</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
					>https://www.neopag.com/termos-de-uso-aplicativo e</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
					>https://www.neopag.com/contrato-cliente-loja</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center; margin-top: 32px;"
					>Baixe o app em https://www.neopag.com/app</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
					>e acompanhe suas compras</p>
					<p
						style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center; margin-top: 32px;"
					>Neopag versão: {{$neopagVersion}}</p>
				</div>
			</div>
		</div>
		<div class="print-button">
			<el-button icon="el-icon-arrow-left" size="small" type="primary" @click="handleBack">Voltar</el-button>
			<el-button icon="el-icon-printer" size="small" type="primary" @click="print">Imprimir</el-button>
		</div>
	</div>
</template>

<style lang="scss">
.print-button {
	width: 300px;
	margin: 0 auto 32px auto;
	text-align: center;
	.el-button {
		width: 120px;
	}
}
</style>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import toCNPJ from "@/helpers/toCNPJ";
import toCPF from "@/helpers/toCPF";
import toMoney from "@/helpers/toMoney";
import momentTz from "moment-timezone";

export default {
	filters: {
		toCNPJ,
		toCPF
	},
	props: {
		onBack: {
			type: Function,
			default: null
		},
		showSale: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			authOrigin: types.AUTH_ORIGIN,
			store: types.STORE_INFO,
			details: types.DEAL_DETAILS,
			response: types.DEAL_MORE_DETAILS,
			saleResponse: types.SALE_MORE_DETAILS,
			customer: types.CLIENT_DETAILS,
			installments: types.DEAL_INSTALLMENTS
		}),
		calculateDownPayment() {
			if (this.showSale) {
				if (
					this.saleResponse.down_payments &&
					Array.isArray(this.saleResponse.down_payments) &&
					this.saleResponse.down_payments.length > 0
				) {
					const { down_payments } = this.saleResponse;
					let amount = 0;
					down_payments.forEach(item => (amount += item.amount));
					return amount;
				}
			} else {
				if (
					this.response.down_payments &&
					Array.isArray(this.response.down_payments) &&
					this.response.down_payments.length > 0
				) {
					const { down_payments } = this.response;
					let amount = 0;
					down_payments.forEach(item => (amount += item.amount));
					return amount;
				}
			}
			return 0;
		}
	},
	methods: {
		handleInsertedAt(data) {
			if (data) return data.inserted_at;
			return null;
		},
		handleBack() {
			if (this.authOrigin === "app") {
				return NativeAppChannel.postMessage("receipt");
			}
			if (this.onBack) {
				return this.onBack();
			}
			this.$router.push({
				name: "Recibo do reparcelamento",
				meta: {
					params: {
						step: 2,
						type: "deal"
					}
				}
			});
		},
		formatDate(date, showHour = true) {
			if (date) {
				return momentTz
					.utc(date)
					.tz("America/Sao_Paulo")
					.format(showHour ? "DD/MM/YYYY HH:MM" : "DD/MM/YYYY");
			}
			return "";
		},
		formatStatementDate(date) {
			return momentTz(date).format("DD/MM/YYYY");
		},
		formatMoney(amount) {
			return toMoney(amount);
		},
		print() {
			const printable = this.$refs.dealReceipt;
			const w = window.open();

			w.document.write(`
				<head>
					<link href="https://fonts.googleapis.com/css?family=Roboto+Mono:400,700" rel="stylesheet">
				</head>
			`);
			w.document.write(printable.innerHTML);
			w.document.write(
				"<scr" +
					'ipt type="text/javascript">' +
					"window.onload = function() { window.print(); window.close(); };" +
					"</sc" +
					"ript>"
			);

			w.document.close(); // necessary for IE >= 10
			w.focus(); // necessary for IE >= 10
		}
	}
};
</script>
