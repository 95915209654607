<template>
  <div>
    <div
      ref="saleReceipt"
      style="position: relative; background: #FFF; max-width: 600px; margin: 0 auto 32px auto; padding: 16px; border-radius: 5px; box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);"
    >
      <div
        v-if="response && !response.active"
        style="position: absolute; top: 244px; left: 60px; transform: rotateZ(-45deg);"
      >
        <h1 style="color: red; font-size: 38px; letter-spacing: 1.124px;">COMPRA CANCELADA</h1>
      </div>
      <div
        class="receipt-container"
        style="background: #FFF; width: 100%; margin: 0; padding: 0; text-align: left;"
      >
        <p
          style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';text-align: center; margin-bottom: 22px;"
        >NEOPAG</p>
        <p
          style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';text-align: center; margin-bottom: 22px;"
        >COMPROVANTE NÃO FISCAL</p>
        <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Data da compra</p>
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Identificador</p>
        </div>
        <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >{{ response ? formatDate(response.sale_date) : '' }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >#{{ response.identifier || 'N/D' }}</p>
        </div>
        <div
          class="row"
          style="display: grid; grid-template-columns: repeat(2, 1fr); margin-top: 16px;"
        >
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Loja</p>
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">CNPJ</p>
        </div>
        <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">{{ store.name }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >{{ store.cnpj || 'N/D' }}</p>
        </div>
        <div
          class="row"
          style="display: grid; grid-template-columns: repeat(2, 1fr); margin-top: 16px;"
        >
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Cliente</p>
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">CPF</p>
        </div>
        <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >{{ response.user.name }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >{{ response.user.cpf || 'N/D' }}</p>
        </div>
        <div class="divider" style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;" />
        <p
          style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Valor da compra: {{ formatMoney(response.amount) }}</p>
        <p
          style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Entrada: {{ formatMoney(calculateDownPayment) }}</p>
        <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >A financiar: {{ formatMoney(response.amount - calculateDownPayment) }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >Juros: {{ response.interest }}%</p>
        </div>
        <p
          style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Valor total: {{ formatMoney(response.total_amount) }}</p>
        <div class="divider" style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;" />
        <div class="installments-row" style="display: grid; grid-template-columns: repeat(3, 1fr);">
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Parcelas</p>
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Vencimento</p>
          <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Valor</p>
        </div>
        <div
          v-for="installment in response.installments"
          :key="installment.number"
          class="installments-row"
          style="display: grid; grid-template-columns: repeat(3, 1fr);"
        >
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >{{ installment.number }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >{{ formatStatementDate(installment && installment.statement ? installment.statement.due_date : '') }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >{{ formatMoney(installment.amount) }}</p>
        </div>
        <div class="divider" style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;" />
        <p
          v-if="response.description"
          style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Descrição: {{ response.description }}</p>
        <div
          v-if="response.description"
          class="divider"
          style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;"
        />
        <p
          style="text-align: center; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Reconheco a dívida acima</p>
        <p
          style="text-align: center; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Assinatura do cliente autorizando essa compra:</p>
        <div
          class="signature"
          style="padding-top: 80px; width: 300px; margin: 0 auto 8px auto; border-bottom: solid 1px #000;"
        />
        <p
          style="text-align: center; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >{{ response.user.name }}</p>
        <p
          style="text-align: center; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >CPF: {{ response.user.cpf }}</p>
        <p
          style="text-align: center; margin-top: 32px; font-size: 14px; font-family: 'Roboto Mono';"
        >Compra realizada de acordo com os termos:</p>
        <p
          style="text-align: center; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >https://www.neopag.com/termos-de-uso-aplicativo e</p>
        <p
          style="text-align: center; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >https://www.neopag.com/contrato-cliente-loja</p>
        <p
          style="text-align: center; margin-top: 32px; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Baixe o app em https://www.neopag.com/app</p>
        <p
          style="text-align: center; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >e acompanhe suas compras</p>
        <p
          style="text-align: center; margin-top: 32px; margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
        >Neopag versão: {{$neopagVersion}}</p>
      </div>
    </div>
    <div style="position: relative; background: #FFF; max-width: 600px; margin: 0 auto 32px auto; padding: 16px;">
      <table><tr>
      <td><el-button icon="el-icon-arrow-left" size="small" type="primary" @click="handleBack">Voltar</el-button></td>
      <td><el-button icon="el-icon-help" size="small"  type="success" @click="shareWhatsapp">WhatsApp</el-button></td>
      <td v-if="this.response.ccb !== null"><el-button icon="el-icon-warning" size="small"  type="success" @click="downloadCCB">CCB</el-button></td>
      <td><el-button
        v-if="authOrigin !== 'app'"
        icon="el-icon-printer"
        size="small"
        type="primary"
        @click="print"
      >Imprimir</el-button></td>
      </tr></table>
    </div>
  </div>
</template>

<style lang="scss">
.print-button {
  width: 300px;
  margin: 0 auto 32px auto;
  text-align: center;
  .el-button {
    width: 120px;
  }
}
</style>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import toCNPJ from "@/helpers/toCNPJ";
import toCPF from "@/helpers/toCPF";
import toMoney from "@/helpers/toMoney";
import momentTz from "moment-timezone";
import axios from "axios";

export default {
  filters: {
    toCNPJ,
    toCPF
  },
  props: {
    onBack: {
      type: Function,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      store: types.STORE_INFO,
      details: types.SALE_DETAILS,
      response: types.SALE_MORE_DETAILS,
      customer: types.CLIENT_DETAILS,
      installments: types.SALE_INSTALLMENTS,
      store_config: types.STORE_CONFIG
    }),
    calculateDownPayment() {
      if (
        this.response.down_payments &&
        Array.isArray(this.response.down_payments) &&
        this.response.down_payments.length > 0
      ) {
        const { down_payments } = this.response;
        let amount = 0;
        down_payments.forEach(item => (amount += item.amount));
        return amount;
      }
      return 0;
    }
  },
  methods: {

    downloadCCB(){

      //console.trace(this.response.ccb);
      //window.alert(this.response.ccb);
      window.open(this.response.ccb, "_blank");

    },


    shareWhatsapp() {

       const retorno = this.$store.dispatch(types.CLIENT_DETAILS, this.response.user.cpf).then((res) => {

         //console.trace(res.data.data);

        const lineBreak = "%0a";
        var whatsappMessage = "http://api.whatsapp.com/send/?phone=" + res.data.data.phone.replace("+","") + "&text=";
        whatsappMessage = whatsappMessage + "```RECIBO DE VENDA```" + lineBreak + lineBreak;
        whatsappMessage = whatsappMessage + "Loja: *" + this.store.name + "*" + lineBreak;
        whatsappMessage = whatsappMessage + "CNPJ: *" + this.store.cnpj + "*" + lineBreak + lineBreak;

        whatsappMessage = whatsappMessage + "Cliente: *" + this.response.user.name + "*" + lineBreak;
        whatsappMessage = whatsappMessage + "CPF: *" + this.response.user.cpf + "*" + lineBreak + lineBreak;

        whatsappMessage = whatsappMessage + "Numero do recibo: *" + this.response.identifier +"+"+ lineBreak;
        whatsappMessage = whatsappMessage + "Data da venda: *" + this.formatDate(this.response.sale_date) +"*"+ lineBreak + lineBreak;

        whatsappMessage = whatsappMessage + "Valor da compra: *" + this.formatMoney(this.response.amount) +"*"+ lineBreak;
        if (this.calculateDownPayment>0) { whatsappMessage = whatsappMessage + "Entrada: *" + this.formatMoney(this.calculateDownPayment) +"*"+ lineBreak; }
        whatsappMessage = whatsappMessage + "A financiar: *" + this.formatMoney(this.response.amount - this.calculateDownPayment) +"*"+ lineBreak;
        if (this.response.interest>0) { whatsappMessage = whatsappMessage + "Juros: *" + this.response.interest + " %" +"*"+ lineBreak;}
        whatsappMessage = whatsappMessage + "Valor total`: *" + this.formatMoney(this.response.total_amount) +"*"+ lineBreak + lineBreak;

        for (let i = 0; i < this.response.installments.length; i++){

            whatsappMessage = whatsappMessage + "Parcela: *" + this.response.installments[i].number +"*";
            whatsappMessage = whatsappMessage + " - Vencimento: *" + this.formatStatementDate(this.response.installments[i] && this.response.installments[i].statement ? this.response.installments[i].statement.due_date : '') +"*";
            whatsappMessage = whatsappMessage + " - Valor: *" + this.formatMoney(this.response.installments[i].amount) + "*" + lineBreak;
        }

        whatsappMessage = whatsappMessage + lineBreak + "Compra realizada de acordo com os termos:" + lineBreak;
        whatsappMessage = whatsappMessage + "https://www.neopag.com/termos-de-uso-aplicativo e https://www.neopag.com/contrato-cliente-loja" + lineBreak;
        whatsappMessage = whatsappMessage + "Baixe o app em https://www.neopag.com/app e acompanhe suas compras" + lineBreak;

        window.open(whatsappMessage, "_blank") || window.location.replace(whatsappMessage);

        }) ;

    },
    handleBack() {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage("receipt");
      }
      if (this.onBack) {
        return this.onBack();
      }
      this.$router.push({
        name: "Recibo da venda",
        meta: {
          params: {
            step: 4,
            type: "sale"
          }
        }
      });
    },
    formatDate(date, showHour = true) {
      return momentTz
        .utc(date)
        .tz("America/Sao_Paulo")
        .format(showHour ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY");
    },
    formatStatementDate(date) {
      if (date) {
        return momentTz(date).format("DD/MM/YYYY");
      }
    },
    formatMoney(amount) {
      return toMoney(amount);
    },
    print() {
      const printable = this.$refs.saleReceipt;
      const w = window.open();

      w.document.write(`
				<head>
					<link href="https://fonts.googleapis.com/css?family=Roboto+Mono:400,700" rel="stylesheet">
				</head>
			`);
      w.document.write(printable.innerHTML);
      w.document.write(
        "<scr" +
          'ipt type="text/javascript">' +
          "window.onload = function() { window.print(); window.close(); };" +
          "</sc" +
          "ript>"
      );

      w.document.close(); // necessary for IE >= 10
      w.focus(); // necessary for IE >= 10

      return true;
    }
  }
};
</script>
