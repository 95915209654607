<template>
  <div v-loading="loading" class="aprovar-clientes">
    <section>
      <div class="wrapper">
        <!-- <el-dialog
					v-loading="submittingSuitability"
					v-if="currentCustomer"
					:visible.sync="showVerificationDialog"
					class="aprovar-clientes__dialog"
					fullscreen
				>
					<el-row :gutter="10">
						<el-col :xs="24" :sm="10">
							<header class="aprovar-clientes__profile">
								<div class="avatar">
									<img :src="currentCustomer.selfie || $avatar" alt="selfie" />
								</div>
								<div class="info">
									<span>
										<strong>{{ currentCustomer.name }}</strong>
									</span>
									<span>{{ currentCustomer.phone | toPhone }}</span>
									<span>{{ currentCustomer.email }}</span>
								</div>
							</header>
							<div class="aprovar-clientes__info">
								<figure>
									<img :src="selfie || $avatar" />
									<figcaption>Foto</figcaption>
								</figure>
								<div class="data">
									<p>
										<small>CPF:</small>
										{{ currentCustomer.cpf | toCPF }}
									</p>
									<p>
										<small>Data de de nascimento:</small>
										{{ currentCustomer.birthdate || ' - ' }}
									</p>
									<p>
										<small>RG:</small>
										{{ currentCustomer.rg || ' - ' }}
									</p>
									<p>
										<small>Endereço:</small>
										{{ formatAddress(currentCustomer.address) || ' - ' }}
									</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="14">
							<suitability
								:limit.sync="askLimit"
								:dialog.sync="showVerificationDialog"
								:submitting.sync="submittingSuitability"
								:handle-success="handleSuitabilitySuccess"
								:questions="questions"
								:client="currentCustomer"
							/>
						</el-col>
					</el-row>
        </el-dialog>-->
        <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="showApproveDialog"
          width="320px"
          class="aprovar-clientes__dialog"
        >
          <div class="avatar">
            <img :src="getSelfie(details)" alt="selfie" />
          </div>
          <score-ruler :neopag-score="clientScoreNeopag.neopag_score" />
          <h3>{{ currentCustomerToApprove.name }}</h3>
          <br />
          <header class="aprovar-clientes__score-info">
            <span>Score</span>
            <span>{{ clientScoreNeopag.neopag_score || 0 }}</span>
            <span>Level</span>
            <span>{{ clientScoreNeopag.level || 0 }}</span>
            <span>Crédito sugerido</span>
            <span
              v-if="currentCustomerToApprove && currentCustomerToApprove.store_customer"
            >{{ $n(currentCustomerToApprove.store_customer.total_credit || 0, 'currency') }}</span>
          </header>
          <hr />
          <div class="aprovar-clientes__score-input">
            <span class="label">Limite</span>
            <div class="el-input">
              <money v-bind="$money" v-model="limit" class="el-input__inner" />
            </div>
          </div>
          <div class="aprovar-clientes__score-actions">
            <el-button type="danger" @click="handleDenial">Recusar</el-button>
            <el-button type="success" @click="handleAprove">Aprovar {{ $n(limit || 0, 'currency') }}</el-button>
          </div>
        </el-dialog>
        <div style="margin: 2rem auto; text-align: center;">
          <div>
            <h2>
              {{`Aguardando sua avaliação ${list.length ? '('+ list.length +')' : ''}`}}
              <el-popover
                placement="bottom-start"
                title="Entenda melhor"
                width="320"
                :trigger="$isMobile ? 'click' : 'hover'"
                content="Os clientes abaixo encontraram sua loja pelo app Neopag e solicitaram o cartão da sua loja. Você pode realizar a análise de crédito desses clientes e decidir se serão aprovados ou não."
              >
                <i
                  slot="reference"
                  class="el-icon-question"
                  style="color: #ff5e44; font-size: 1.5rem; margin-left: 1"
                ></i>
              </el-popover>
            </h2>
          </div>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
              <!-- <el-button
						type="secondary"
						size="mini"
						icon="el-icon-arrow-left"
						@click="$router.push({ name: 'Clientes' })">
						Todos os clientes
					</el-button>
					<el-button
						v-if="list.length"
						:loading="loading"
						size="mini"
						icon="el-icon-refresh"
              @click="updateTable" />-->
              <el-input
                v-if="list.length"
                v-model="clientQuery"
                :autofocus="true"
                name="client-search"
                auto-complete="false"
                aria-autocomplete="false"
                style="margin: 1rem;"
              >
                <template slot="prepend">Buscar</template>
              </el-input>
            </el-col>
          </el-row>
          <el-table
            :data="paginatedList"
            :header-cell-style="{ fontSize: '18px' }"
            empty-text="Nenhum convite pendente."
            style="width: 100%;"
          >
            <el-table-column min-width="300">
              <!-- :label="`Aguardando sua avaliação ${list.length ? '('+ list.length +')' : ''}`" -->
              <template slot-scope="scope">
                <div class="aprovar-clientes__perfil">
                  <div class="avatar" v-if="scope.row.selfie">
                    <img :src="scope.row.selfie || $avatar(scope.row.name)" alt="selfie" />
                  </div>
                  <div class="generated-avatar" v-else>
                    <image-avatar :name="scope.row.name" />
                  </div>
                  <div class="info">
                    <span class="info__name">{{ scope.row.name }}</span>
                    <span class="info__cpf">CPF: {{ scope.row.cpf | toCPF }}</span>
                    <span class="info__phone">CEL: {{ scope.row.phone | toPhone }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="100" align="right">
              <template slot-scope="scope">
                <div class="button-wrapper">
                  <el-button
                    style="width: 100%"
                    :loading="fetchingUser"
                    size="small"
                    type="success"
                    @click="showDialog(scope.$index, scope.row.cpf)"
                  >Consultar</el-button>
                  <el-button
                    style="width: 100%"
                    :loading="fetchingUser"
                    type="danger"
                    size="small"
                    @click="handleIgnore(scope.row)"
                  >Ignorar</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="list.length"
            :current-page.sync="pageNumber"
            :page-count="pageCount"
            class="aprovar-clientes__paginacao"
            layout="prev, pager, next"
          />
        </div>
      </div>
<!--
      <div class="wrapper custom-component--grid">
        <h2 v-if="potentialList.length" style="text-align: center">
          Sugestões de novos clientes para sua loja ({{ potentialList.length }})
          <el-popover
            placement="bottom-start"
            title="Entenda melhor"
            width="320"
            :trigger="$isMobile ? 'click' : 'hover'"
            content="Os clientes sugeridos pela Neopag são potenciais clientes próximos à sua loja, que possuem compras em lojas diferentes do seu segmento de atuação e que possuem bom comportamento de compra e pagamento. Você pode realizar a análise de crédito desses clientes e decidir se quer convidá-lo para ter o cartão da sua loja, o cliente receberá um SMS com o convite."
          >
            <i
              slot="reference"
              class="el-icon-question"
              style="color: #ff5e44; font-size: 1.5rem; margin-left: 1"
            ></i>
          </el-popover>
        </h2>
        <h2 v-else style="text-align: center">
          Não há clientes engajados no seu negócio nessa região
          <el-popover
            placement="bottom-start"
            title="Entenda melhor"
            width="320"
            :trigger="$isMobile ? 'click' : 'hover'"
            content="Os clientes sugeridos pela Neopag são potenciais clientes próximos à sua loja, que possuem compras em lojas diferentes do seu segmento de atuação e que possuem bom comportamento de compra e pagamento. Você pode realizar a análise de crédito desses clientes e decidir se quer convidá-lo para ter o cartão da sua loja, o cliente receberá um SMS com o convite."
          >
            <i
              slot="reference"
              class="el-icon-question"
              style="color: #ff5e44; font-size: 1.5rem; margin-left: 1"
            ></i>
          </el-popover>
        </h2>
        <neopag-suggestions
          :list="potentialList"
          :prevent-invite="fetchingUser"
          class="suggestions"
          @invite="inviteCustomer"
        />
      </div>
-->
    </section>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapGetters } from "vuex";
import { Money } from "v-money";
import * as types from "@/store/types";
import toPerson from "@/helpers/toPerson";
import toCPF from "@/helpers/toCPF";
import phone from "@/helpers/phone";
import NeopagSuggestions from "@/components/NeopagSuggestions";
import ScoreRuler from "@/components/ScoreRuler";
import ImageAvatar from "@/components/ImageAvatar";

export default {
  components: {
    Money,
    ScoreRuler,
    NeopagSuggestions,
    ImageAvatar
  },
  filters: {
    toCPF,
    toPhone: phone.format
  },
  data() {
    return {
      submittingSuitability: false,
      showVerificationDialog: false,
      showApproveDialog: false,
      askLimit: false,
      questions: {},
      fetchingUser: false,
      fetchingScore: false,
      loading: false,
      selfie: "",
      limit: 0,
      currentCustomer: {},
      currentCustomerToApprove: {},
      clientQuery: "",
      pageNumber: 1,
      pageNumber2: 1,
      perPage: 10
    };
  },
  computed: {
    ...mapGetters({
      list: types.STORE_CUSTOMERS_TO_APPROVE,
      potentialList: types.STORE_POTENTIAL_CUSTOMERS,
      templates: types.SUITABILITY_QUESTIONS,
      details: types.CLIENT_DETAILS,
      clientScoreNeopag: types.CLIENT_SCORE_NEOPAG,
      storeID: types.AUTH_ACTIVE_STORE
    }),
    fuse: function() {
      return new Fuse(this.list, {
        keys: [
          {
            name: "name",
            weight: 0.8
          },
          {
            name: "cpf",
            weight: 0.2
          }
        ],
        threshold: 0.15,
        tokenize: true
      });
    },
    filteredList: function() {
      const filteredList = this.clientQuery
        ? this.fuse.search(this.clientQuery)
        : this.list;

      return filteredList;
    },
    pageCount: function() {
      const l = this.filteredList.length;
      return Math.ceil(l / this.perPage);
    },
    paginatedList: function() {
      const start = (this.pageNumber - 1) * this.perPage;
      const end = start + this.perPage;

      if (!this.filteredList.length) {
        return [];
      }

      if (this.filteredList.length < start) {
        return this.filteredList;
      }

      return this.filteredList.slice(start, end);
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.SUITABILITY_QUESTIONS);
      await this.$store.dispatch(types.STORE_POTENTIAL_CUSTOMERS, {
        storeID: this.$store.getters[types.AUTH_ACTIVE_STORE]
      });
      await this.$store.dispatch(types.STORE_CUSTOMERS_TO_APPROVE, {
        storeID: this.$store.getters[types.AUTH_ACTIVE_STORE]
      });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async handleIgnore(user) {
      try {
        this.loading = true;
        const { store_customer_id } = user.store_customer;
        await this.$store.dispatch(types.STORE_IGNORE_USER, {
          user_id: store_customer_id
        });
        this.$store.dispatch(types.STORE_CUSTOMERS_TO_APPROVE, {
          storeID: this.$store.getters[types.AUTH_ACTIVE_STORE]
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async updateTable() {
      this.loading = true;
      try {
        await this.$store.dispatch(types.STORE_CUSTOMERS_TO_APPROVE, {
          storeID: this.$store.getters[types.AUTH_ACTIVE_STORE]
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async inviteCustomer(customer) {
      // try {
      //   this.currentCustomer = customer;
      //   this.questions = toPerson({
      //     name: this.currentCustomer.name || "Cliente",
      //     gender: this.currentCustomer.gender || 1,
      //     templates: this.templates
      //   });
      //   this.fetchingUser = true;
      //   await this.$store.dispatch(types.CLIENT, this.currentCustomer.id);
      //   await this.$store.dispatch(types.CLIENT_SET);
      //   this.askLimit = true;
      //   this.fetchingUser = false;
      //   this.selfie = this.getSelfie(this.details);
      //   this.$nextTick(() => {
      //     this.showVerificationDialog = true;
      //   });
      // } catch (error) {
      //   if (this.$raven && error.status === 500) {
      //     this.$raven.captureException(error.message, { req: error });
      //   }
      //   this.$notify.error({ title: error.message });
      // }
      try {
        this.fetchingUser = true;
        await this.$store.dispatch(types.CLIENT, customer.id);
        await this.$store.dispatch(types.CLIENT_SET);
        this.fetchingUser = false;
        this.$router.push({
          name: "Consulta Neopag - CPF",
          params: {
            store_customer_id: customer.store_customer_id,
            cpf: customer.cpf
          }
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async showDialog(index, cpf) {
      try {
        this.currentCustomer = this.paginatedList[index];
        this.fetchingUser = true;
        await this.$store.dispatch(types.CLIENT, this.currentCustomer.id);
        await this.$store.dispatch(types.CLIENT_SET);
        this.fetchingUser = false;
        this.$router.push({
          name: "Consulta Neopag - CPF",
          params: {
            store_customer_id: this.currentCustomer.store_customer
              .store_customer_id,
            cpf: cpf
          }
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handleSuitabilitySuccess() {
      try {
        this.currentCustomerToApprove = this.currentCustomer;
        this.fetchingScore = true;
        await this.$store.dispatch(types.CLIENT_SCORE_NEOPAG, {
          userID: this.currentCustomerToApprove.id,
          useBureau: false,
          storeId: this.storeID
        });
        await this.$store.dispatch(types.CLIENT_SET);
        this.limit = this.currentCustomerToApprove.store_customer.total_credit;
        this.fetchingScore = false;
        this.$nextTick(() => {
          this.showApproveDialog = true;
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    formatAddress(address) {
      if (!address) return "";
      let str = "";
      if (address.street) str += address.street;
      if (address.number) str += ", " + address.number;
      if (address.city) str += " - " + address.city;
      if (address.uf) str += ", " + address.uf;
      return str.trim();
    },
    getSelfie(user) {
      if (!user.documents) return false;
      const selfie = user.documents.find(it => it.type === "selfie");
      return selfie ? selfie.url : "";
    },
    async handleAprove() {
      this.submitting = true;
      await this.$store
        .dispatch(types.CLIENT_UPDATE_STORE, {
          id: this.currentCustomerToApprove.store_customer.store_customer_id,
          storePayload: {
            store_customer: {
              total_credit: this.limit,
              status_id: 1
            }
          }
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.$notify.success({
        title: "Cliente aprovado!"
      });
      this.closeAndUpdateApproval();
    },
    async handleDenial() {
      this.submitting = true;
      await this.$store
        .dispatch(types.CLIENT_UPDATE_STORE, {
          id: this.currentCustomerToApprove.store_customer.store_customer_id,
          storePayload: {
            store_customer: {
              total_credit: this.limit,
              status_id: 6
            }
          }
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.$notify.success({
        title: "Cliente recusado!"
      });
      this.closeAndUpdateApproval();
    },
    async closeAndUpdateApproval() {
      this.submitting = false;
      try {
        await this.$store.dispatch(types.STORE_CUSTOMERS_TO_APPROVE, {
          storeID: this.$store.getters[types.AUTH_ACTIVE_STORE]
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.showApproveDialog = false;
    }
  }
};
</script>

<style lang="scss">
.button-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .el-button + .el-button {
    margin: 0.5rem 0;
  }
  @media screen and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .el-button {
      width: 100%;
    }
    .el-button + .el-button {
      margin: 10px 0 0 0;
    }
  }
}
.aprovar-clientes {
  position: relative;
  width: calc(100% - 32px);
  max-width: 980px;
  margin: 1rem auto 0 auto;

  section {
    display: grid;
    grid-template-columns: 60% 35%;
    grid-column-gap: 5%;
    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
    }
  }

  h3 {
    margin-top: 2rem;
    font-size: 18px;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0 0 0.5rem;
    .el-input {
      flex: 1;
    }
    .el-button {
      flex: 0 auto;
    }

    @include medium-down {
      .el-button:nth-of-type(1) {
        flex: 0 100%;
        margin-right: 0;
        margin-bottom: 8px;
      }
      .el-button:nth-of-type(2) {
        flex: 0 46px;
        margin-right: 0;
        margin-left: 0;
      }
      .el-input {
        flex: 0 calc(100% - 54px);
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }

  &__paginacao {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }

  &__perfil {
    display: flex;
    flex-flow: row wrap;

    .avatar,
    .info {
      flex: 0 auto;
    }

    .avatar {
      display: flex;
      flex: 0 2.5rem;
      height: 2.5rem;
      margin: auto 1rem auto 0;
      background-color: $base-background;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% auto;
      border-radius: 50%;
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
      }
      @include box-shadow(1);
    }
    .generated-avatar {
      margin-right: 1rem;
    }

    .info {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      min-width: 0;

      &__name,
      &__cpf {
        flex: 100%;
      }

      &__name {
        padding-right: 0.5rem;
        overflow: hidden;
        font-family: $title-font;
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__cpf,
      &__phone {
        margin-top: 0.5rem;
        font-family: $base-font;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
      }
    }

    .info {
      flex: 1;
    }
  }

  &__dialog {
    height: 100%;
    @include medium-down {
      height: auto;
    }
    .avatar {
      width: 90px;
      height: 90px;
      margin: -20px auto 20px;
      background-color: $base-background;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% auto;
      border-radius: 50%;
      img {
        width: 90px;
        height: 90px;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
      }
      @include box-shadow(1);
    }
    .el-dialog__body {
      height: calc(100% - 30px);
      @include medium-down {
        height: auto;
      }
    }
    .el-row {
      height: 100%;
      @include medium-down {
        height: auto;
      }
    }
    .el-col {
      height: 100%;
      @include medium-down {
        height: auto;
      }
    }
  }

  &__score-info {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1rem;

    span {
      flex: 0 50%;
      font-family: $title-font;
      line-height: 1.55;
      font-size: 15px;
    }
  }

  &__score-input {
    margin-top: 1rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .label {
      flex: 0 auto;
      margin-right: 1rem;
      font-family: $title-font;
    }
    .el-input {
      flex: 1;
    }
  }

  &__score-actions {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }

  &__info {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid rgba($laranja, 0.5);
    figure {
      float: left;
      width: 35%;
      img {
        width: 100%;
        @include box-shadow(1);
      }
      figcaption {
        text-align: center;
        font-family: $title-font;
        font-style: italic;
      }
    }
    .data {
      display: inline-block;
      padding-left: 1rem;
      width: 65%;
      p {
        margin-bottom: 1rem;
        line-height: 1.25;
      }
      small {
        display: block;
        line-height: 1;
        font-size: 10px;
        opacity: 0.85;
      }
    }
    .selfie {
      flex: 0 100%;
      span {
        font-family: $title-font;
        font-size: 1rem;
      }

      .image {
        margin-top: 0.5rem;
        width: 100%;
        padding-top: 66%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 100%;
      }
    }

    p {
      font-family: $title-font;
      line-height: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__profile {
    padding-top: 3.95rem;
    display: flex;
    flex-flow: row wrap;
    .info {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-flow: column wrap;
      padding-left: 1rem;
      font-family: $title-font;
      span:nth-child(1) {
        font-size: 1rem;
      }
    }
    @include medium-down {
      padding-top: 0;
    }
    span {
      display: block;
      flex: 1;
      line-height: 26px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .avatar {
      flex: 0 80px;
      height: 80px;
      width: 80px;
      margin: 0;
      background-color: $base-background;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% auto;
      border-radius: 50%;
      img {
        height: 80px;
        width: 80px;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
      }
      @include box-shadow(1);
    }
  }
  .suggestions {
    margin-top: 1rem;
    min-height: 300px;
    width: 100%;
    @media screen and (min-width: 767px) {
      margin-top: 4rem;
    }
  }
  .custom-component--grid {
    @media screen and (min-width: 767px) {
      margin: 2rem 0;
    }
  }
}
</style>
