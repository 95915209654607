<template>
  <Reports />
</template>

<script>
import Reports from "@/containers/Customers/Reports";
export default {
  components: {
    Reports
  }
};
</script>

<style lange="scss" scoped>
</style>
