<template>
  <div>
    <div
      ref="paymentReceipt"
      style="background: #FFF; max-width: 600px; margin: 0 auto 32px auto; padding: 16px; border-radius: 5px; box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);"
    >
      <div
        class="receipt-container"
        style="background: #FFF; width: 100%; margin: 0; padding: 0; text-align: left;"
      >
        <div class="receipt-wrapper" style="width: 100%; text-align: left; margin-top: 16px;">
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';text-align: center; margin-bottom: 22px;"
          >NEOPAG</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';text-align: center; margin-bottom: 22px;"
          >COMPROVANTE NÃO FISCAL</p>
          <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
            <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Data do recebimento</p>
            <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Identificador</p>
          </div>
          <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
            <p
              style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
            >{{ response ? formatDate(response.inserted_at) : '' }}</p>
            <p
              style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
            >#{{ response && response.identifier ? response.identifier : 'N/D' }}</p>
          </div>
          <div
            class="row"
            style="display: grid; grid-template-columns: repeat(2, 1fr); margin-top: 16px;"
          >
            <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Loja</p>
            <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">CNPJ</p>
          </div>
          <div
            class="row"
            style="display: grid; grid-template-columns: repeat(2, 1fr); margin-top: 16px;"
          >
            <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">{{ store.name }}</p>
            <p
              style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
            >{{ store.cnpj || 'N/D' }}</p>
          </div>
          <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
            <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">Cliente</p>
            <p style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';">CPF</p>
          </div>
          <div class="row" style="display: grid; grid-template-columns: repeat(2, 1fr);">
            <p
              style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
            >{{ response.user.name }}</p>
            <p
              style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
            >{{ response.user.cpf || 'N/D' }}</p>
          </div>
          <div class="divider" style="width: 100%; border-top: dashed 1px #000; margin: 8px 0;" />
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >Valor do pagamento: {{ formatMoney(response.amount) }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >Valor do desconto: {{ formatMoney(response.discount) }}</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono';"
          >Valor total: {{ formatMoney(response.amount - response.discount) }}</p>
          <p
            style="margin-top: 32px; font-size: 14px; font-family: 'Roboto Mono'; text-align: center; margin-top: 32px;"
          >Recebimento realizado de acordo com os termos:</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
          >https://www.neopag.com/termos-de-uso-aplicativo e</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
          >https://www.neopag.com/contrato-cliente-loja</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center; margin-top: 32px;"
          >Baixe o app em https://www.neopag.com/app</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center;"
          >e acompanhe suas compras</p>
          <p
            style="margin: 0; font-size: 14px; font-family: 'Roboto Mono'; text-align: center; margin-top: 32px;"
          >Neopag versão: {{$neopagVersion}}</p>
        </div>
      </div>
    </div>
    <div class="print-button">
      <el-button icon="el-icon-arrow-left" size="small" type="primary" @click="handleBack">Voltar</el-button>
      <el-button icon="el-icon-help" size="small"  type="success" @click="shareWhatsapp">WhatsApp</el-button>
      <el-button
        v-if="authOrigin !== 'app'"
        icon="el-icon-printer"
        size="small"
        type="primary"
        @click="print"
      >Imprimir</el-button>
    </div>
  </div>
</template>

<style lang="scss">
.print-button {
  width: 300px;
  margin: 0 auto 32px auto;
  text-align: center;
  .el-button {
    width: 120px;
  }
}
</style>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import toCNPJ from "@/helpers/toCNPJ";
import toCPF from "@/helpers/toCPF";
import toMoney from "@/helpers/toMoney";
import momentTz from "moment-timezone";

export default {
  filters: {
    toCNPJ,
    toCPF
  },
  props: {
    onBack: {
      type: Function,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      store: types.STORE_INFO,
      details: types.PAYMENT_DETAILS,
      response: types.PAYMENT_MORE_DETAILS,
      customer: types.CLIENT_DETAILS
    })
  },
  methods: {

      shareWhatsapp() {


       const retorno = this.$store.dispatch(types.CLIENT_DETAILS, this.response.user.cpf).then((res) => {

        const lineBreak = "%0a";
        var whatsappMessage = "http://api.whatsapp.com/send/?phone=" + res.data.data.phone.replace("+","") + "&text=";
        whatsappMessage = whatsappMessage + "```RECIBO DE PAGAMENTO```" + lineBreak + lineBreak;
        whatsappMessage = whatsappMessage + "Loja: *" + this.store.name + "*" + lineBreak;
        whatsappMessage = whatsappMessage + "CNPJ: *" + this.store.cnpj + "*" + lineBreak + lineBreak;

        whatsappMessage = whatsappMessage + "Cliente: *" + this.response.user.name + "*" + lineBreak;
        whatsappMessage = whatsappMessage + "CPF: *" + this.response.user.cpf + "*" + lineBreak + lineBreak;

        whatsappMessage = whatsappMessage + "Data do pagamento: *" + this.formatDate(this.response.inserted_at) +"*"+ lineBreak + lineBreak;
        whatsappMessage = whatsappMessage + "Valor do pagamento: *" + this.formatMoney(this.response.amount) +"*"+ lineBreak;
        if (this.calculateDownPayment>0) { whatsappMessage = whatsappMessage + "Entrada: *" + this.formatMoney(this.calculateDownPayment) +"*"+ lineBreak; }
        whatsappMessage = whatsappMessage + "Valor do desconto: *" + this.formatMoney(this.response.discount) +"*"+ lineBreak;
        if (this.response.interest>0) { whatsappMessage = whatsappMessage + "Juros: *" + this.response.interest + " %" +"*"+ lineBreak;}
        whatsappMessage = whatsappMessage + "Valor total: *" + this.formatMoney(this.response.amount - this.response.discount) +"*"+ lineBreak + lineBreak;

        whatsappMessage = whatsappMessage + lineBreak + "Compra realizada de acordo com os termos:" + lineBreak;
        whatsappMessage = whatsappMessage + "https://www.neopag.com/termos-de-uso-aplicativo e https://www.neopag.com/contrato-cliente-loja" + lineBreak;
        whatsappMessage = whatsappMessage + "Baixe o app em https://www.neopag.com/app e acompanhe suas compras" + lineBreak;

        window.open(whatsappMessage, "_blank") || window.location.replace(whatsappMessage);

        }) ;

    },
    handleBack() {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage("receipt");
      }
      if (this.onBack) {
        return this.onBack();
      }
      this.$router.push({
        name: "Recibo do recebimento",
        meta: {
          params: {
            step: 2,
            type: "payment"
          }
        }
      });
    },
    formatDate(date, showHour = true) {
      return momentTz
        .utc(date)
        .tz("America/Sao_Paulo")
        .format(showHour ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY");
    },
    formatMoney(amount) {
      return toMoney(amount);
    },
    print() {
      const printable = this.$refs.paymentReceipt;
      const w = window.open();

      w.document.write(`
				<head>
					<link href="https://fonts.googleapis.com/css?family=Roboto+Mono:400,700" rel="stylesheet">
				</head>
			`);
      w.document.write(printable.innerHTML);
      w.document.write(
        "<scr" +
          'ipt type="text/javascript">' +
          "window.onload = function() { window.print(); window.close(); };" +
          "</sc" +
          "ript>"
      );

      w.document.close(); // necessary for IE >= 10
      w.focus(); // necessary for IE >= 10
    }
  }
};
</script>
