<template>
  <main class="tabela-clientes">
    <div>
      <div class="tabela-clientes__header">
        <!-- <el-button
          :loading="loading"
          class="refresh"
          size="mini"
          icon="el-icon-refresh"
          @click="updateTable"
        />-->
        <el-input v-model="clientQuery" :autofocus="true" name="client-search" size="small">
          <template slot="prepend">Busca:</template>
        </el-input>
        <!--
        <upgrade-suggester :show="!allowConsult" class="more">
          <el-button
            style="margin-right: 10px;"
            :size="$isMobile ? 'mini' : 'small'"
            type="primary"
            @click="allowConsult ? novoCliente() : ``"
          >Cadastrar cliente</el-button>
        </upgrade-suggester>
        -->
        <el-badge
          v-if="authOrigin !== 'app'"
          :max="99"
          :hidden="!showBadge"
          :value="badgeCount"
          class="more"
          style="margin-right: 1.5rem;"
        >
          <upgrade-suggester :show="!allowConsult">
            <el-button
              type="success"
              icon="el-icon-share"
              :size="$isMobile ? 'mini' : 'small'"
              @click="allowConsult ? $router.push({ name: 'Aprovar clientes' }) : ``"
            >Clientes Neopag</el-button>
          </upgrade-suggester>
        </el-badge>
      </div>
      <div style="width: 100%; text-align: center;">
        <el-radio-group
          v-model="category"
          :size="$isMobile ? 'mini' : 'small'"
          style="margin: 22px auto;"
          @change="handleCategoryChange"
        >
          <el-radio-button label="client">{{$isMobile? 'Aprovados' : 'Clientes aprovados' }}</el-radio-button>
          <el-radio-button label="all">{{$isMobile? 'Todos' : 'Todos os clientes' }}</el-radio-button>
          <el-radio-button label="not_client">{{$isMobile? 'Reprovados' : 'Cliente reprovados' }}</el-radio-button>
        </el-radio-group>
      </div>
      <el-table
        v-loading="loading"
        ref="tabelaClientes"
        :data="paginatedList.data"
        style="width: 100%"
        @sort-change="handleSort"
        @cell-click="(row) => handleClientMenuClick(row)"
      >
        <el-table-column :width="$isMobile ? '414': ''" property="name" label="Cliente" sortable>
          <template slot-scope="scope">
            <div class="tabela-clientes__cliente">
              <radial-progress
                :diameter="60"
                :stroke-width="5"
                :completed-steps="userPercentageScore(scope.row.user.user_score)"
                :total-steps="100"
                :start-color="userPercentageColor(scope.row.user.user_score)"
                :stop-color="userPercentageColor(scope.row.user.user_score)"
                inner-stroke-color="rgba(108, 115, 124, 0.3)"
              >
                <template v-if="scope.row.user.selfie">
                  <div class="avatar">
                    <img :src="scope.row.user.selfie || $avatar" alt="selfie" />
                  </div>
                </template>
                <template v-else>
                  <div class="generated-avatar">
                    <image-avatar :name="scope.row.user.name" />
                  </div>
                </template>
              </radial-progress>
              <div class="score">
                <!-- <div
                  class="score__nivel"
                >{{ (scope.row.user.user_score && scope.row.user.user_score.label) || 'Nível ∅' }}</div>-->
                <!-- <score-ruler :height="10" :score="scope.row.user.user_score" /> -->
              </div>
              <div class="info">
                <span
                  class="info__name"
                  @click="handleClientMenuClick(scope.row)"
                >{{ scope.row.user.name }}</span>
                <span class="info__cpf">CPF: {{ scope.row.user.cpf | toCPF }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!$isMobile"
          align="center"
          property="store_status.id"
          width="140"
          height="200"
          label="Status"
          sortable
        >
          <template slot-scope="scope">
            <neopag-status :status-object="scope.row.store_status" />
          </template>
        </el-table-column>
        <el-table-column v-if="!$isMobile" align="center" width="160">
          <template slot="header">
            <svgicon class="svg-icon" name="cell" />
            <span>Telefone</span>
          </template>
          <template slot-scope="scope">
            <div class="tabela-clientes__contato">
              <div class="row">
                <span>{{ scope.row.user.phone? scope.row.user.phone.replace('+55', '') : '' | toPhone }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageNumber"
        :page-count="paginatedList.total_pages"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        class="tabela-clientes__paginacao"
        layout="prev, pager, next, sizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
      />
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import toCPF from "@/helpers/toCPF";
import phone from "@/helpers/phone";
import * as types from "@/store/types";
import _ from "lodash";
import NeopagStatus from "@/components/NeopagStatus";
import UpgradeSuggester from "@/components/UpgradeSuggester";
import RadialProgress from "vue-radial-progress";
import ImageAvatar from "@/components/ImageAvatar";

export default {
  filters: {
    toCPF,
    toPhone: phone.format
  },
  components: {
    RadialProgress,
    NeopagStatus,
    UpgradeSuggester,
    ImageAvatar
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      clientQuery: "",
      customerStatus: [],
      selecetedStatus: "all",
      order_by: "name_asc",
      category: "client",
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      storeID: types.AUTH_ACTIVE_STORE,
      subscription: types.SUBSCRIPTION_CONFIG,
      paginatedList: types.CLIENT_PAGINATED_LIST,
      customersToApprove: types.STORE_CUSTOMERS_TO_APPROVE,
      storeUsers: types.STORE_USERS,
      scoreNeopag: types.CLIENT_SCORE_NEOPAG
    }),
    allowConsult() {
      const clients = this.storeUsers.active + this.storeUsers.rejected;
      const limit = this.subscription.iugu_activated
        ? this.subscription.clients_number
        : 2;
      if (parseFloat(this.subscription.price) === 0) {
        return limit === -1 || clients < limit;
      } else {
        return limit === -1 || this.storeUsers.active < limit;
      }
    },
    showBadge() {
      return this.badgeCount > 0;
    },
    badgeCount() {
      return this.customersToApprove.length;
    }
  },
  watch: {
    clientQuery() {
      this.pageNumber = 1;
      this.searchClient();
    }
  },
  async created() {
    try {
      await this.$store.dispatch(types.CLIENT_PAGINATED_LIST, {
        storeID: this.storeID,
        page: 1,
        page_size: 10,
        status: this.selecetedStatus,
        order_by: this.order_by,
        category: this.category
      });
      await this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
      const status = await this.$store.dispatch(types.STORE_CUSTOMERS_STATUS);
      this.customerStatus = status;
      this.loading = false;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async handleCategoryChange(category) {
      await this.$store
        .dispatch(types.CLIENT_PAGINATED_LIST, {
          storeID: this.storeID,
          page: this.pageNumber,
          page_size: this.pageSize,
          q: this.clientQuery,
          status: this.selecetedStatus,
          order_by: this.order_by,
          category
        })
        .catch(error => {
          this.loading = false;
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    },
    async handleSort({ prop, order }) {
      this.loading = true;
      let order_by = "";
      if (prop === "name") {
        order_by = "name";
      } else {
        order_by = "status";
      }
      this.order_by =
        order === "ascending" ? `${order_by}_asc` : `${order_by}_desc`;
      await this.$store
        .dispatch(types.CLIENT_PAGINATED_LIST, {
          storeID: this.storeID,
          page: this.pageNumber,
          page_size: this.pageSize,
          q: this.clientQuery,
          status: this.selecetedStatus,
          order_by: this.order_by,
          category: this.category
        })
        .catch(error => {
          this.loading = false;
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    },
    async handleStatusChange(status) {
      this.loading = true;
      await this.$store
        .dispatch(types.CLIENT_PAGINATED_LIST, {
          storeID: this.storeID,
          page: this.pageNumber,
          page_size: this.pageSize,
          q: this.clientQuery,
          status,
          order_by: this.order_by,
          category: this.category
        })
        .catch(error => {
          this.loading = false;
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    },
    searchClient: _.debounce(async function() {
      this.loading = true;
      try {
        await this.$store.dispatch(types.CLIENT_PAGINATED_LIST, {
          storeID: this.storeID,
          page: 1,
          q: this.clientQuery,
          status: this.selecetedStatus,
          order_by: this.order_by,
          category: this.category
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    }, 500),
    async handleSizeChange(size) {
      this.loading = true;
      this.pageNumber = 1;
      this.pageSize = size;
      await this.$store
        .dispatch(types.CLIENT_PAGINATED_LIST, {
          storeID: this.storeID,
          q: this.clientQuery,
          page: 1,
          page_size: size,
          status: this.selecetedStatus,
          order_by: this.order_by,
          category: this.category
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    },
    async changePage(page) {
      this.loading = true;
      await this.$store
        .dispatch(types.CLIENT_PAGINATED_LIST, {
          storeID: this.storeID,
          q: this.clientQuery,
          page,
          page_size: this.pageSize,
          status: this.selecetedStatus,
          order_by: this.order_by,
          category: this.category
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    },
    async updateTable() {
      this.loading = true;
      await this.$store
        .dispatch(types.CLIENT_PAGINATED_LIST, {
          storeID: this.storeID,
          page: this.pageNumber,
          page_size: this.pageSize,
          q: this.clientQuery,
          status: this.selecetedStatus,
          order_by: this.order_by,
          category: this.category
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    },
    notifyCopy(success, type) {
      this.$notify[success ? "success" : "error"]({
        title: success ? "Sucesso!" : "Erro!",
        message: success ? `${type} copiado` : "não foi possível copiar!"
      });
    },
    fallbackCopyTextToClipboard(type, value) {
      const fake = document.createElement("textarea");
      fake.value = value;
      document.body.appendChild(fake);
      fake.focus();
      fake.select();
      try {
        var successful = document.execCommand("copy");
        this.notifyCopy(successful, type);
      } catch (err) {
        this.notifyCopy(false, type);
      }
      document.body.removeChild(fake);
    },
    contact(type, value) {
      if (event) {
        event.preventDefault();
      }
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(type, value);
      } else {
        navigator.clipboard.writeText(value).then(
          () => {
            this.notifyCopy(true, type);
          },
          () => {
            this.notifyCopy(false, type);
          }
        );
      }
    },
    async novoCliente() {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage("query");
      }
      await this.$store.dispatch(types.CLIENT_RESET);
      this.$router.push({ name: "Consulta Neopag - CPF" });
    },
    async handleClientMenuClick(client) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(client.user.id);
      }
      await this.$store.dispatch(types.CLIENT_RESET);
      return this.$router.push({
        name: "Perfil - Histórico de lançamentos",
        params: { clientID: client.user.id }
      });
    },
    userPercentageScore(user_score) {
      if (user_score.current_score) {
        const score = (user_score.current_score * 100) / 60000; // percentage score where 60.000 is equal to 100%;
        return score;
      }
      return 0;
    },
    userPercentageColor(user_score) {
      if (user_score.level) {
        if (user_score.level <= 2) {
          return "rgba(151, 4, 12, 0.7)";
        } else if (user_score.level > 2 && user_score.level <= 5) {
          return "rgba(253, 152, 39, 0.7)";
        } else {
          return "rgba(108, 167, 84, 0.7)";
        }
      }
      return "rgba(151, 4, 12, 0.7)";
    }
  }
};
</script>

<style lang="scss">
.tabela-clientes {
  position: relative;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    padding: 32px 0;
  }
  padding: 32px 16px 0 16px;
  background-color: #ffffff;

  .el-table .cell {
    overflow: visible !important;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 0.5rem;

    .el-button {
      &:first-child {
        margin-right: 10px;
      }
    }

    .el-button-group {
      flex: 0 270px;
    }

    > span {
      flex: 0 auto;
    }
    .el-input {
      margin-right: 1rem;
      max-width: 600px;
    }
    @include medium-down {
      .refresh {
        width: 46px;
      }
      .el-input {
      margin-right: 0;

        margin-bottom: 1rem;
      }
      .import {
        margin-top: 8px;
      }
      .more {
        + .more {
          margin-left: 8px;
        }
        margin-right: 0 !important;
      }
    }
  }

  &__cliente {
    display: flex;
    flex-shrink: 1;
    height: 60px;
    @include medium-down {
      height: 60px;
    }

    .avatar,
    .score,
    .info {
      flex: 0 auto;
    }

    .avatar {
      display: flex;
      flex: 0 40px;
      height: 40px;
      margin: auto 0;
      background-color: $base-background;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% auto;
      border-radius: 50%;
      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
      }
      @include box-shadow(1);
    }
    .score,
    .info {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      min-width: 0;
      @media screen and (max-width: 767px) {
        margin-left: 1rem;
      }

      &__nivel,
      &__regua,
      &__name,
      &__cpf {
        flex: 100%;
      }

      &__nivel {
        padding-bottom: 0.5rem;
        font-family: $title-font;
        font-size: 0.65rem;
        line-height: 0.85rem;
        color: $preto;
      }

      &__name {
        padding-right: 0.5rem;
        margin-bottom: 1rem;
        overflow: hidden;
        font-family: $title-font;
        font-size: 11px;
        font-weight: 700;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include medium-down {
          padding-right: 0;
        }
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          color: $azul;
          text-decoration: underline;
        }
      }

      &__cpf {
        font-family: $base-font;
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
      }
    }

    .score {
      box-sizing: content-box;
      flex: 0 30px;
      width: 30px;
      padding: 0 1rem;
      text-align: center;
      @include medium-down {
        display: none;
      }
    }

    .info {
      // flex: 1;
    }
  }

  &__contato {
    display: flex;
    flex-flow: row wrap;
    .row {
      flex: 0 100%;
      overflow: hidden;
      font-family: $base-font;
      font-size: 12px;
      line-height: 26px;
      @include medium-down {
        line-height: 21px;
      }
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        position: relative;
        top: 2px;
        left: 2px;
      }
    }
    .svg-icon {
      width: 26px;
      height: 26px;
      @include medium-down {
        height: 21px;
      }
      margin: 0 auto;
      cursor: pointer;
      transition: all 0.3s;
      &.disabled {
        cursor: not-allowed;
        box-shadow: none !important;
        opacity: 0.5;
      }
      &:hover {
        @include box-shadow(1);
      }
    }
  }

  &__paginacao {
    margin: 1rem 0 0;
    text-align: center;
  }

  .el-table {
    min-height: 388px;
    @include medium-down {
      min-height: 288px;
    }
  }
  .el-table__row td .cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-table th .cell {
    font-size: 12px;
  }
  .el-table th,
  .el-table td {
    padding: 4px 0;
  }

  &__btn-group {
    span > .el-button {
      border-radius: 0;
    }
    span:nth-child(1) > .el-button {
      border-right: none;
    }
    span:nth-child(2) > .el-button {
      border-right: none;
    }
  }
}
.svg-icon {
  width: 26px;
  height: 26px;
  @include medium-down {
    height: 21px;
  }
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s;
  &.disabled {
    cursor: not-allowed;
    box-shadow: none !important;
    opacity: 0.5;
  }
  &:hover {
    @include box-shadow(1);
  }
}
</style>
