<template>
  <section>
    <Filters />
    <Reports />
  </section>
</template>

<script>
import Filters from "@/components/Customers/Filters";
import Reports from "@/components/Customers/Reports";
export default {
  components: {
    Filters,
    Reports
  }
};
</script>

<style lang="scss" scoped>
</style>
