<template>
  <el-popover
    :title="statusObject.label"
    :content="statusObject.description"
    :trigger="$isMobile ? 'click' : 'hover'"
  >
    <el-tag slot="reference" :class="`neopag-status--${statusObject.id}`" disable-transitions>
      <span :class="iconClass" class="lnr">
        <font-awesome-icon :icon="iconClass" />
      </span>
      {{ statusObject.label }}
    </el-tag>
  </el-popover>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faCheck,
  faSearch,
  faBan,
  faCommentSlash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faExclamationTriangle, faCheck, faSearch, faBan, faCommentSlash);

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    statusObject: {
      type: Object,
      default: () => {
        return {
          description: "Cliente ativo e com crédito aprovado pela loja",
          id: 1,
          label: "Ativo",
          status: "active"
        };
      }
    }
  },
  computed: {
    iconClass() {
      const { id } = this.statusObject;
      const icons = {
        1: "check",
        2: "ban",
        3: "search",
        4: "search",
        5: "check",
        6: "ban",
        7: "ban",
        8: "comment-slash",
        9: "lnr-cross-circle",
        10: "check",
        11: "check",
        12: "comment-slash"
      };
      return icons[id];
    }
  }
};
</script>
<style lang="scss">
$slug: neopag-status-- !default;

$statuses: (
  1: (
    colors: (
      #67c23a,
      #fff
    )
  ),
  2: (
    colors: (
      #000,
      #fff
    )
  ),
  3: (
    colors: (
      #1cbeca,
      #000
    )
  ),
  4: (
    colors: (
      #1cbeca,
      #fff
    )
  ),
  5: (
    colors: (
      #f1e88c,
      #6c737c
    )
  ),
  6: (
    colors: (
      #f1565d,
      #fff
    )
  ),
  7: (
    colors: (
      #f1565d,
      #fff
    )
  ),
  8: (
    colors: (
      #6c737c,
      #fff
    )
  ),
  9: (
    colors: (
      #f1565d,
      #fff
    )
  ),
  10: (
    colors: (
      #f1e88c,
      #6c737c
    )
  ),
  11: (
    colors: (
      #f1e88c,
      #6c737c
    )
  ),
  12: (
    colors: (
      #6c737c,
      #fff
    )
  )
);

[class*="#{$slug}"] {
  @include box-shadow(1);

  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-family: $title-font;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  height: 18px;
  letter-spacing: 1px;
  line-height: 8px;
  margin: 1px;
  // overflow: hidden;
  padding: 4px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition: box-shadow 0.3s ease;
  white-space: nowrap;
  width: 120px;
  will-change: box-shadow;

  .lnr {
    @include box-shadow(1);
    background: white;
    border-radius: 100%;
    font-size: 12px;
    padding: 3px;
    top: -11px;
    position: absolute;
    left: -14px;
    color: #6c737c;
    // z-index: 10;
  }

  &:hover {
    @include box-shadow(2);
  }

  &:active {
    @include box-shadow(1);
    outline: none;
  }

  + [class*="#{$slug}"] {
    margin-left: 10px;
  }
}

@each $status, $d in $statuses {
  $bgColor: nth(map-get($d, colors), 1);
  $fontColor: nth(map-get($d, colors), 2);
  $borderColor: darken($bgColor, 10);

  .#{$slug}#{$status} {
    color: $fontColor;
    background: $bgColor;
    border-color: $borderColor;
  }
}
</style>
