<template>
  <div class="score-ruler">
    <svg :height="`${height * 2 + 4}px`">
      <text
        :style="{
          fontSize: `${height}px`
        }"
        :y="`${height}px`"
        :fill="textFill"
        x="50%"
      >{{ showScore ? neopagScore : text }}</text>
      <rect
        v-for="(rule, index) in ruler"
        :key="index"
        :x="rule.x"
        :fill="rule.fill"
        :y="`${height + 4}px`"
        :height="`${height}px`"
        stroke-width="1px"
        stroke="rgba(0,0,0,0.125)"
        width="10%"
      />
      <rect
        :x="position"
        :y="`${height + 3}px`"
        :height="`${height + 2}px`"
        class="position"
        width="2px"
        fill="#444"
      />
    </svg>
    <div v-if="showDebt" class="score-ruler__debt" @click="showDebtDialog = true">
      <i class="el-icon el-icon-info" />
    </div>

    <el-dialog :width="$isMobile ? '100%' : '50%'" :visible.sync="showDebtDialog">
      <h3 style="line-height: 1.45">Encotramos os seguintes débitos vinculados ao CPF consultado:</h3>
      <el-table
        :header-cell-style="{fontSize: '0.75rem'}"
        :data="debtInfo"
        size="mini"
        class="score-ruler__debt-table"
        style="width: 100%;"
      >
        <el-table-column property="institution" label="Insti." />
        <el-table-column width="100" align="center" property="debt_date" label="Data" />
        <el-table-column
          :formatter="row => $n(row.amount, 'currency')"
          align="right"
          width="110"
          property="amount"
          label="Valor"
        />
        <el-table-column width="60" align="center" property="uf" label="UF" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import * as d3 from "d3";

const SCORES = {
  1: { min: 0, max: 2200, limit: 0, fill: "#F3CCCD", color: "#ff5e44" },
  2: { min: 2201, max: 6600, limit: 10, fill: "#F3CCCD", color: "#ff5e44" },
  3: { min: 6601, max: 13200, limit: 15, fill: "#FBE5CE", color: "#ffd16e" },
  4: { min: 13201, max: 22000, limit: 20, fill: "#FBE5CE", color: "#ffd16e" },
  5: { min: 22001, max: 33000, limit: 25, fill: "#FBE5CE", color: "#ffd16e" },
  6: { min: 33001, max: 47000, limit: 28, fill: "#D6E7D2", color: "#005c67" },
  7: { min: 47001, max: 60000, limit: 30, fill: "#D6E7D2", color: "#005c67" },
  8: { min: 60001, max: 72200, limit: 35, fill: "#D6E7D2", color: "#005c67" },
  9: { min: 72201, max: 89000, limit: 40, fill: "#D6E7D2", color: "#005c67" },
  10: { min: 89001, max: 100000, limit: 45, fill: "#D6E7D2", color: "#005c67" }
};
export default {
  props: {
    score: {
      type: Object,
      // eslint-disable
      default: () => ({
        current_score: 0,
        description: undefined,
        id: null,
        label: "Nivel ∅",
        level: undefined,
        max_level_score: 10,
        min_level_score: 0
      })
      // eslint-enable
    },
    height: {
      type: Number,
      default: 16
    },
    neopagScore: {
      type: Number,
      default: -1
    },
    text: {
      type: String,
      default: "confiança"
    },
    showScore: {
      type: Boolean,
      default: false
    },
    showDebt: {
      type: Boolean,
      default: false
    },
    debtInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const ruler = Object.keys(SCORES).map((it, index) => {
      return {
        fill: SCORES[it].fill,
        color: SCORES[it].color,
        x: index * 10 + "%"
      };
    });
    return {
      ruler,
      scores: SCORES,
      currentFill: undefined,
      showDebtDialog: false
    };
  },
  computed: {
    position() {
      if (this.neopagScore === -1) {
        const range = this.score.max_level_score - this.score.min_level_score;
        const value = this.score.max_level_score - this.score.current_score;
        const percentage = ((value / range) * 100).toFixed(2);
        if (percentage >= 100) {
          return 0;
        }
        return (this.score.level - 1) * 10 + "%";
      } else {
        let position = 0;
        const cur = this.neopagScore;
        Object.keys(this.scores).map(it => {
          if (cur >= this.scores[it].min && cur <= this.scores[it].max) {
            const scale = d3
              .scaleLinear()
              .domain([this.scores[it].min, this.scores[it].max])
              .range([0, 10]);
            position = (+it - 1) * 10 + scale(+cur);
          }
        });
        return position + "%";
      }
    },
    level() {
      if (this.neopagScore >= 0) {
        const cur = this.neopagScore;
        return Object.keys(this.scores)
          .map(it => {
            if (cur >= this.scores[it].min && cur <= this.scores[it].max) {
              return it;
            }
          })
          .filter(it => it);
      }
      return 1;
    },
    textFill() {
      if (this.showScore) {
        return this.ruler[this.level - 1].color;
      }
      return "rgba(108, 115, 124, 0.65)";
    }
  }
};
</script>
<style lang="scss">
.score-ruler {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  svg {
    width: 100%;
    flex: 1;
  }
  text {
    font-family: $title-font;
    line-height: 1;
    text-anchor: middle;
    font-weight: 300;
    fill: #6c737c;
  }
  .position {
    transition: all 1s ease-in;
  }
  &__debt {
    flex: 0 auto;
    padding-left: 1.25rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .el-icon {
      color: red;
      line-height: 1;
      animation: pulse 1s infinite;
      box-shadow: 0 0 0 0 rgba(red, 0.5);
      border-radius: 50%;
      position: relative;
      cursor: pointer;
    }
  }

  &__debt-table {
    td .cell {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(red, 0.5);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(red, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(red, 0);
  }
}
</style>
