<template>
  <h1>Lista de customers</h1>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
