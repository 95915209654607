<template>
  <div class="letter-generate">
    <span class="letter">{{initialName}}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    }
  },
  computed: {
    initialName() {
      if (!this.name) {
        return "";
      }
      const arrayName = this.name.split(" ").filter(item => item !== " ");
      if (!arrayName) {
        return "";
      } else if (arrayName.length === 1) {
        const firstName = arrayName.shift().charAt(0);
        return `${firstName}`;
      } else {
        const firstName = arrayName.shift().charAt(0);
        const lastName = arrayName.pop().charAt(0);
        return `${firstName}${lastName}`;
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.letter-generate {
  width: 40px;
  height: 40px;
  background-color: #f8f9fd;
  border-radius: 50%;
  // position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.letter {
  color: gray;
  font-size: 1.5rem;
}
</style>
