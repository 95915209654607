<template>
  <sale-receipt
    v-if="authOrigin === 'app' && $route.query.action === 'sale/SALE_MORE_DETAILS' || $route.params.type === 'sale'"
    :on-back="goBack"
  />
  <payment-receipt
    v-else-if="authOrigin === 'app' && $route.query.action === 'payment/PAYMENT_MORE_DETAILS' || $route.params.type === 'payment'"
    :on-back="goBack"
  />
  <payment-receipt
    v-else-if="authOrigin === 'app' && $route.query.action === 'deal/DEAL_MORE_DETAILS' || $route.params.type === 'payment_deal'"
    :on-back="goBack"
  />
  <deal-receipt
    v-else
    :on-back="goBack"
    :show-sale="true"
  />
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

import SaleReceipt from "@/components/Sale/Receipt";
import PaymentReceipt from "@/components/Payment/Receipt";
import DealReceipt from "@/components/Deal/Receipt";
export default {
  components: {
    SaleReceipt,
    PaymentReceipt,
    DealReceipt,
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN
    })
  },
}
</script>
